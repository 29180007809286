import appProductivity from "../assets/images/logo.png"
import appFinances from "../assets/images/app/finances/logo.png"
import appFrases from "../assets/images/app/frases/logo.png"
import ebookProdutividade from "../assets/images/ebookProdutividade.png"
import ebookHabitos from "../assets/images/ebookHabitos.png"
import ebookMetas from "../assets/images/ebookMetas.png"
import cursoHabitos from "../assets/images/cursoHabitos.png"
import cursoProdutividade from "../assets/images/cursoProdutividade.png"

export function parseToNumber(string) {
  if (!string) return 0;
  return parseInt(string.replace(/\D/g, ''));
}

export const formatToPrice = (value) => Intl.NumberFormat('pt-BR',{
  style:'currency',
  currency: 'BRL'
}).format(value)

export const Products = {
  apps: [
    {
      url: "/apps/productivity",
      title: "Flynow - Produtividade",
      text:
        "Gerencie suas tarefas, crie bons hábitos e defina metas eficientes. Tudo o que você precisa para ser mais produtivo em apenas 1 app!",
      img: appProductivity,
      price: 199.90
    },
    {
      url: "/apps/finances",
      title: "Flynow - Finanças Pessoais",
      text:
        "Simplifique sua vida financeira! Tenha total controle sobre seus gastos e orçamentos, defina metas financeiras e visualize tudo por meio de estatísticas e gráficos.",
      img: appFinances,
      price: 199.90,
    },
    {
      url: "/apps/phrases",
      title: "Flynow - Frases de Motivação",
      text:
        "Comece o dia com uma dose de motivação! Inspire-se com frases sobre empreendedorismo, disciplina, sabedoria e muito mais.",
      img: appFrases,
      price: 29.99,
    },
  ],
  ebooks: [
    {
      url: "/guia-definitivo-para-a-produtividade",
      title: "Guia definitivo para a produtividade",
      text:
        "23 técnicas (+6 BÔNUS) para ser mais produtivo e alcançar seus objetivos!",
      img: ebookProdutividade,
      price: 39.99,
    },
    {
      url: "/metas-e-planejamento",
      title: "Metas e Planejamento",
      text:
        "6 passos essenciais para criar metas + 6 tipos de planejamentos + 3 bônus EXCLUSIVOS em PDF!",
      img: ebookMetas,
      price: 29.99,
    },
    {
      url: "/guia-completo-habitos",
      title: "Guia completo sobre hábitos",
      text:
        "10 táticas (+2 BÔNUS) incríveis para criar e manter hábitos no longo prazo!",
      img: ebookHabitos,
      price: 29.99,
    },
  ],
  cursos: [
    {
      url: "/curso-domine-a-arte-da-produtividade",
      title: "Domine a arte da produtividade",
      text: `Descubra como ser mais produtivo e gerir melhor seu tempo para alcançar os seus objetivos - sem lista interminável de tarefas e com muito mais tempo livre para fazer o que quiser!`,
      img: cursoProdutividade,
      price: 149.90,
    },
    {
      url: "/curso-domine-seus-habitos",
      title: "Domine seus hábitos: A chave para uma vida extraordinária",
      text: `Aprenda a criar e manter bons hábitos, estabelecer rotinas eficientes e ter mais constância para construir sua vida extraordinária.`,
      img: cursoHabitos,
      price: 119.90,
    },
  ],
}
