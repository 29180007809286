import React, { useEffect, useState } from "react"
import "../assets/fonts/Poppins/Poppins-Light.ttf"
import "../assets/fonts/Poppins/Poppins-Bold.ttf"
import "../assets/fonts/Poppins/Poppins-Regular.ttf"
import "../components/layout.css"
import styled from "styled-components"
import colors from "../colors"
import { Row } from "../components/atomics"
import { getQueryParam, setParams } from "../utils/query"
import { URL_EMAIL, URL_INSTA_FLYNOW, URL_WHATSAPP } from "../constants"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { formatToPrice, Products } from "../utils/product"
import { useParams } from "../hooks"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2% 8%;

  .icon {
    font-size: 2rem;
    color: ${colors.primary2};
    cursor: pointer;
    transition: all 0.4s linear;
  }
`

const Button = styled.a`
  text-align: center;
  padding: 15px 30px;
  color: white;
  background: ${colors.primary2};
  border-radius: 40px;
  font-family: 'Poppins-Bold';
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  text-decoration: none;
  width: 80%;
  align-self: center;
`

const GuiaDefinitivoParaProdutividade = () => {
  const {params} = useParams()

  const data = {
    id: "compra_individual",
    title: "COMPRA INDIVIDUAL",
    sections: [
      {
        cupom: 'FLYN25',
        tax_free: 25,
        section: "APLICATIVOS (PLANO PREMIUM VITALÍCIO)",
        data: Products.apps.map(i=> ({...i, url: i.url + '/premium/?cupom=FLYN25', newPrice: formatToPrice(i.price * (100-20)/100)})),
      },
      {
        cupom: 'BFZWA40',
        tax_free: 40,
        section: "CURSOS",
        data: Products.cursos.map(i=> ({...i, url: i.url + '/?cupom=BFZWA40', newPrice: formatToPrice(i.price * (100-40)/100)})),
      },
      {
        cupom: 'BFZWA40',
        tax_free: 40,
        section: "EBOOKS",
        data: Products.ebooks.map(i=> ({...i, url: i.url + '/?cupom=BFZWA40', newPrice: formatToPrice(i.price * (100-40)/100)})),
      },
    ],
  }

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_" + data.id)
  }, [])

  const onCheckout = () => {
    sendEvent("click_checkout_" + data.id)
  }

  return (
    <Container>
      <h1
        style={{ textAlign: "center", fontWeight: "black", display: "inline" }}
      >
        <h1 style={{ color: colors.primary2, display: "inline" }}>
          BLACK FRIDAY:
        </h1>{" "}
        {data.title}
      </h1>
      <h4
        style={{
          background: colors.red,
          color: "#fff",
          textAlign: "center",
          margin: 5,
          padding: 5,
        }}
      >
        ATÉ 40% OFF (SOMENTE ATÉ 30/11)
      </h4>
      {data.sections.map(item => (
        <>
          <br />
          <br />
          <h3 style={{ color: colors.primary2 }}> <span style={{ color: colors.red }}>[{item.tax_free}%OFF]</span>  {item.section}</h3>
          {item.data.map(i => (
            <Row style={{ marginTop: 20 }}>
              <img src={i.img} style={{ height: 100, objectFit: "contain" }} />
              <div style={{ marginLeft: 10 }}>
                <h6 style={{ textTransform: "uppercase" }}>{i.title}</h6>
                <span
                  style={{
                    marginBottom: 4,
                    marginTop: 3,
                  }}
                >
                  {i.text}
                </span>
                <h3 style={{ color: colors.primary2 }}>
                  <span style={{ fontSize: '.8rem' }}>DE</span> <s>{i.price}</s> <span style={{ fontSize: '.8rem' }}>POR</span> {i.newPrice}
                </h3>
                <a href={setParams(i.url,{
                      ...params,
                      prefilled_email: params.email,
                      client_reference_id: params.user_id,
                })}>
                  <button
                    style={{
                      cursor: "pointer",
                      color: colors.white,
                      backgroundColor: colors.primary2,
                      border: "none",
                      padding: "6px 24px",
                      borderRadius: 8,
                      fontSize: "0.8rem",
                      marginTop:4
                    }}
                  >
                    Adquirir com {item.tax_free}%OFF
                  </button>
                </a>
              </div>
            </Row>
          ))}
        </>
      ))}

      <br />
      <br />
      <h3 style={{ textAlign: "center" }}>Alguma dúvida?</h3>
      <br />
      <span style={{ textAlign: "center" }}>
        Se tiver qualquer dúvida entre em contato conosco!
      </span>
      {[
        {
          link: URL_WHATSAPP,
          img: <FaWhatsapp className="icon" />,
          description: "31 999107753",
        },
        {
          link: URL_EMAIL,
          img: <MdMailOutline className="icon" />,
          description: "support@appflynow.com",
        },
        {
          link: URL_INSTA_FLYNOW,
          img: <FaInstagram className="icon" />,
          description: "@appflynow",
        },
      ].map(i => (
        <a
          href={i.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ alignSelf: "center" }}
        >
          <Row style={{ marginTop: 10, alignItems: "center" }}>
            {i.img}
            <h5 style={{ color: colors.primary2 }}>{i.description}</h5>
          </Row>
        </a>
      ))}
    </Container>
  )
}
export default GuiaDefinitivoParaProdutividade
